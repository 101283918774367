/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 4 jun. 2023, 22:46:03
    Author     : Leo
*/
.left {
  margin: 8.1%;
}
.right {
  margin: 8.1%;
}
.ed-texto-izquierda {
  margin-bottom: 20px;
  text-align: center;
}

.ed-contenedor {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color:#6ececd;
}

.ed-circulo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: rgb(50, 161, 153);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  margin: 50px;
  text-align: center;
}

.ed-circulo-aux {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: rgb(50, 161, 153);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  margin: 50px;
  text-align: center;
}

.ed-circulo-aux:hover {
  transform: scale(1.1);
}

.ed-circulo-aux img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 55%;
  height:100%;
  width:100%
}

@media (max-width: 768px) {
  .ed-circulo-aux {
    width: 200px;
    height: 200px;
     flex-direction: column;
  }
}


.ed-circulo:hover {
  transform: scale(1.1);
}

.ed-circulo img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 55%;
  height:100%;
  width:100%
}

@media (max-width: 768px) {
  .ed-circulo {
    width: 200px;
    height: 200px;
     flex-direction: column;
  }
}

.ed-dropdown-container {
  display: inline-table;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}


.ed-dropdown-button {
  display: flex;
  flex-direction: column;
  align-items: center;
}



.ed-dropdown-content {
  width: 230px;
  background-color: #fff;
  padding: 10px;
  text-align: center;
  margin-bottom: 8px;
}
.ed-dropdowns-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.ed-dropdown-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ed-dropdown-container:not(:last-child) {
  margin-right: 5px; /* Espacio reducido entre los cuadros */
}

@media (max-width: 900px) {
  .ed-dropdowns-container {
    flex-direction: column;
  }
  
  .ed-dropdown-container:not(:last-child) {
    margin-right: 0;
    margin-bottom: 10px; /* Espacio entre los cuadros en modo de una columna */
  }
}

.ed-body-text-style-3{
font-family: NovecentoDemibold;
font-size: 23pt;
color: #168EA8;
font-weight: normal;
font-style: normal;
text-decoration: none;
line-height: 45pt;
}

.ed-body-style-3 {
    background-color: rgba(255, 255, 255, 0.7);
    opacity: 0.9;
    width: 100%;
    max-width: 1000px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    max-height: 200px;
  }
   .ed-dropdown-button-aux {
    background-color: #2aa9a3;
    opacity:0.9;
    width: 100%;
    max-width: 1000px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 0px;
   
      
  }
   .ed-dropdown-button {
    background-color: #2aa9a3;
    opacity:0.9;
    width: 100%;
    max-width: 1000px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 20px;
   
      
  }
  @media (max-width: 900px) {
        
    .ed-dropdown-button {
      height: 100%;
      max-height: 110px;
    }
  
  }
  

.ed-text-and-circulo {
    display: flex; 
    align-items: center;
     max-width: 800px; /* Ajusta el ancho máximo del elemento */
  max-height: 250px; /* Ajusta la altura máxima del elemento */
}

@media (max-width: 768px) {
  .ed-text-and-circulo {
    flex-direction: column;
     max-width: 100%; /* Ajusta el ancho máximo del elemento */
  max-height: 100%;
    
  }
}

.card {
        max-width: 830px;
        height: 98%;
        display: inline-block;
        margin-top: 3%;
        margin: 5px 5px 5px 5px;
        background-color: rgba(255, 255, 255, 0);
        margin-left:8%;
    }
    
@media (max-width: 768px) {
  .card {
    margin-left:auto;
  }
}
.ed-container {
  display: flex;
  flex-direction: column;
  margin: 0px 80px 0px 80px;
  padding-bottom: 20px;
}

.ed-item {
  width: 100%;
  max-width: 850px;
}

.ed-left {
  align-self: flex-start;
}

.ed-right {
  align-self: flex-end;
}

@media (max-width: 600px) {
  .ed-container {
    flex-direction: column;
    margin: 5px 5px 0px 5px;
  }

  .ed-left, .ed-right {
    align-self: auto;
  }
}

.ed-topography-dropdown-content{
    font-size: 26px;
    color: #3C810D;

}

@media (max-width: 600px) {
  .ed-topography-dropdown-content {
      padding-left: 15px;
      padding-right: 15px;
      font-size: 26px;
      color: #3C810D;
  }

}

.ed-button-topography {
  position: relative;
  text-align:center;
  margin-left:10px;
  margin-right:10px;
  margin-top:10px;
}

