@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');

@font-face {
  font-family: 'TovariSans';
  src: local('TovariSans'), url(fonts/TovariSans.ttf) format('truetype');
}
@font-face {
  font-family: 'Microsoft Yi Baiti';
  src: local('msyi'), url(fonts/msyi.ttf) format('truetype');
}
@font-face {
  font-family: 'Gil Sans MT';
  src: local('GilSansMT'), url(fonts/GilSansMT.ttf) format('truetype');
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif,'Josefin Sans', cursive,"Open Sans","Source Sans Pro";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", "Cantarell",
    monospace;
}


