.imgSocobioma{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto; 
    width: 100%;
    max-width: 842px;
    height: auto;
}

.slider-container {
}

.my_fullscreen_div {
    width: 100%;
    height: 100%;
}
