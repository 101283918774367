@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
@font-face {
  font-family: 'TovariSans';
  src: local('TovariSans'), url(/static/media/TovariSans.7df4b2de.ttf) format('truetype');
}
@font-face {
  font-family: 'Microsoft Yi Baiti';
  src: local('msyi'), url(/static/media/msyi.5d22bd08.ttf) format('truetype');
}
@font-face {
  font-family: 'Gil Sans MT';
  src: local('GilSansMT'), url(/static/media/GilSansMT.d084e511.ttf) format('truetype');
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif,'Josefin Sans', cursive,"Open Sans","Source Sans Pro";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", "Cantarell",
    monospace;
}



.slide{
    background-color: #b4b4b4 !important; /**poner color del slider del carrusel rgb(21, 93, 108)*/
}

.carousel-container .carousel .slide {
  opacity: 0.6;
  transition: opacity 0.8s ease-in-out;
}
.carousel .control-dots {
    z-index:999;
    margin: 10px -15px !important;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
}
.carousel-container .carousel .slide.selected {
  opacity: 1;
}
.carousel-container {
  position: relative;
  overflow: hidden; /* Asegura que el contenido del carrusel no se salga del contenedor */
}

.carousel-container::after {
    content:'';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px; /* Ajusta la altura del sombreado según tus necesidades */
  background: linear-gradient(to top, rgba(0, 0, 0, 0.6), transparent);
}

.rounded-image {
  border-radius: 5px; /* Establece un radio de 10px para lograr esquinas redondeadas */
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.responsive {
  max-height: 32px;
  max-width: 150px;
}
.responsiveAnimales {
    max-height: 45px;
    max-width: 1500px;
    margin-left: auto;
    margin-right: 10px;
    margin-bottom: 20px; 
    margin-top: 3px;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.restOfScreen{
    background-image: url("/fondo.jpg");
    height: 100%;
    min-height: 100vh;
    margin: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.restOfScreenForInicioScreen2{
    background-image: url("/images/reportes/seccion_reportes_1.JPG");
    height: 100%;
    min-height: 100vh;
    margin: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.restOfScreenForReporteScreen{
    background-image: url("/images/reportes/seccion_reportes_1.JPG");
    height: 100%;
    min-height: 100vh;
    margin: 0 auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.restOfScreenForConozcanosScreen{
    background-image: url("/images/conozcanos/seccion_quienesSomos.jpg");
    background-color:white;
    height: 100%;
    min-height: 110vh;
    margin: 0 auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;   
    

}
.restOfScreenForNuestroTrabajoScreen{
    background-image: url("/images/nuestroTrabajo/pilares_1.jpg");
    /*background-color: #8ecac9;*/
    height: 100%;
    min-height: 100vh;
    margin: 0 auto;
/*    background-position: bottom;*/
    /*background-position-y: 80%;  revisar porque la propiedad podría ser solo de CHROME*/
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;

}
.restOfScreenForInicioScreen{
    background-image: url("/fondo.jpg");
    height: 100%;
    min-height: 100vh;
    margin: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll;

}

.restOfScreenForColaboraScreen{
    background-image: url("/images/colabora/seccion_colabora.jpg");
    height: 100%;
    min-height: 100vh;
    margin: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.restOfScreenForEducacionScreen{
    background-image: url("/images/educacion/seccion_educacion_1.jpg");
    height: 100%;
    min-height: 100vh;
    margin: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.restOfScreenForContactanosScreen{
/*    background-image: url("/images/contactanos/seccion_contactanos.jpg");*/
    height: 100%;
    min-height: 30vh;
    margin: 0;
    background-color: #2aa9a3;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.restOfScreenForVoluntariadoScreen{
    background-image: url("/images/voluntariado/seccion_voluntariado.jpg");
    height: 100%;
    min-height: 100vh;
    margin: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}
  
.fondoTransparente {
  background-color: rgba(255, 255, 255, .93);
  margin: 0 15% 0 15%;
  border-radius: 5px;
  padding-bottom: 20px;
}

.fondoPag {
  background-color: rgba(255, 255, 255, .93);
  border-radius: 5px;
  display: block;
  white-space: pre;
}

.bottomText{
  vertical-align:bottom;
  position: fixed;
  bottom: 0;

  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 4px; 

  color: #a8a8a8  ;
  font-size: 9px;
}
.section {    
}

.section-dark {
  background: #333;
  color: white;
}

.section-content {
    margin: 0 auto;      
}

.left {
    position: relative;
    float: left; 
}

.right {
    float: right;
    position:relative;
}

#newLine { width:100% ; height:10px; }

.makeStyles-content-4 {
    padding:0px !important;
}

.jss4 {
    padding:0px !important;
}

img {
    max-width: 100%;
    max-height: 100%;
}

.imgCarousel {
    max-width: 99%;
    max-height: 900px;
    margin: 5px auto;
    border-radius:5px;
}

.makeStyles-toolbar-5{
    min-height: auto !important;
}

.jss5{
    min-height: auto !important;
}

.imgOne{   
    background-image: url("/images/carousel_images/1.jpg"); 
    height:500px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size:  contain;
}

.imgTwo {
    background-image: url("/images/carousel_images/2.jpg");
    height:500px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.imgTree {
    background-image: url("/images/carousel_images/3.jpg");
    height:500px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
}

.imgFour {
    background-image: url("/images/carousel_images/4.jpg");
    height:500px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
}

.imgFive {
    background-image: url("/images/carousel_images/5.jpg");
    height:500px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
}

.imgSix {
    background-image: url("/images/carousel_images/6.jpg");
    height:500px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
}

.imgSeven {
    background-image: url("/images/carousel_images/7.jpg");
    height:500px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
}

@-webkit-keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.blinking {
  -webkit-animation: scaleAnimation 0.5s ease-in-out infinite alternate;
          animation: scaleAnimation 0.5s ease-in-out infinite alternate;;
}
.leaflet-container {
  height: 420px;
  width: 100%;
}
.spinner {
    width: 100%;
    height: 400px;
  }
  
  .spinner > div {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  
.responsiveLogo{
    width: 80%;
    height: 80%;
}

.successCard{
    text-align: center
}
/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 10-may-2020, 19:27:17
    Author     : Leo
*/
.left {
  margin: 8.1%;
}
.right {
  margin: 8.1%;
}

.MuiAvatar-img{
    object-fit: contain !important;
}

.dropdown-container {
  display: inline-table;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}


.ns-dropdown-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2aa9a3;
  max-width: 1000px;
  opacity:0.9;
   width: 100%;
   height:50px;
   justify-content: center;
     border-radius: 5px;
         margin: 0 auto;
         margin-top:30px;
}
@media (max-width: 450px) {
  .ns-dropdown-button {
    display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2aa9a3;
  max-width: 1000px;
  opacity:0.9;
   width: 100%;
   height:85px;
   justify-content: center;
border-radius: 5px;
    margin: 0 auto;
    margin-top:5px;
         
  }
  

}
   




.circle-container {
  width: 230px; /* Ajusta el tamaño del cuadrado según tus necesidades */
  height: 210px;
  /*border: 1px solid #000;*/ /*PARA PONER BORDE*/
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.7);
}

.circle {
     
 width: 155px;
  height: 135px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;

  overflow: hidden;
}

.circle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dropdown-content {
  width: 230px;
  background-color: #fff;
  padding: 10px;
  text-align: center;
  margin-bottom: 8px;
}
.dropdowns-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.dropdown-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropdown-container:not(:last-child) {
  margin-right: 5px; /* Espacio reducido entre los cuadros */
}

@media (max-width: 900px) {
  .dropdowns-container {
    flex-direction: column;
  }
  
  .dropdown-container:not(:last-child) {
    margin-right: 0;
    margin-bottom: 10px; /* Espacio entre los cuadros en modo de una columna */
  }
}

.body-text-style-3{
font-family: NovecentoDemibold;
font-size: 23pt;
color: #168EA8;
font-weight: normal;
font-style: normal;
text-decoration: none;
line-height: 45pt;
}

.body-style-3 {
    background-color: rgba(255, 255, 255, 0.7);
    opacity: 0.9;
    width: 100%;
    max-width: 1000px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    max-height: 200px;
  }
  
   .dropdown-button {
    background-color: #2aa9a3;
    opacity:0.9;
    width: 100%;
    max-width: 1000px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 20px;
   
      
  }
  @media (max-width: 900px) {
        
    .dropdown-button {
      height: 100%;
      max-height: 110px;
    }
  
  }
/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 10-may-2020, 19:27:17
    Author     : Leo
*/

.left {
  margin: 8.1%;
}
.right {
  margin: 8.1%;
}

.MuiAvatar-img{
    object-fit: contain !important;
}

.nt-dropdown-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;  
}

.nt-dropdown-container:not(:last-child) {
  margin-right: 5px; /* Espacio reducido entre los cuadros */
}

@media (max-width: 900px) {
  .nt-dropdowns-container {
    flex-direction: column;
  }
  
  .nt-dropdown-container:not(:last-child) {
    margin-right: 0;
    margin-bottom: 10px; /* Espacio entre los cuadros en modo de una columna */
  }
}

.nt-dropdown-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.nt-circle-container {
  width: 260px; /* Ajusta el tamaño del cuadrado según tus necesidades */
  height: 260px;
  /*border: 1px solid #000;*/ /*PARA PONER BORDE*/
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.9);

}

.nt-circle {
    
  width: 185px;
  height: 185px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  margin-bottom:20px;
  overflow: hidden;
}

.nt-circle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.nt-dropdown-content {
    border-radius: 5px;
  width: 260px;
  background-color: #fff;
  padding: 10px;
  text-align: center;
  margin-bottom: 8px;
}
.nt-dropdowns-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}





.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

}
.card {
  width: 100%;
  max-width: 750px;
  background-color: rgba(255, 255, 255, 0.95)
}
/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 4 jun. 2023, 22:46:03
    Author     : Leo
*/
.left {
  margin: 8.1%;
}
.right {
  margin: 8.1%;
}
.ed-texto-izquierda {
  margin-bottom: 20px;
  text-align: center;
}

.ed-contenedor {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color:#6ececd;
}

.ed-circulo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: rgb(50, 161, 153);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  margin: 50px;
  text-align: center;
}

.ed-circulo-aux {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: rgb(50, 161, 153);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  margin: 50px;
  text-align: center;
}

.ed-circulo-aux:hover {
  transform: scale(1.1);
}

.ed-circulo-aux img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 55%;
  height:100%;
  width:100%
}

@media (max-width: 768px) {
  .ed-circulo-aux {
    width: 200px;
    height: 200px;
     flex-direction: column;
  }
}


.ed-circulo:hover {
  transform: scale(1.1);
}

.ed-circulo img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 55%;
  height:100%;
  width:100%
}

@media (max-width: 768px) {
  .ed-circulo {
    width: 200px;
    height: 200px;
     flex-direction: column;
  }
}

.ed-dropdown-container {
  display: inline-table;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}


.ed-dropdown-button {
  display: flex;
  flex-direction: column;
  align-items: center;
}



.ed-dropdown-content {
  width: 230px;
  background-color: #fff;
  padding: 10px;
  text-align: center;
  margin-bottom: 8px;
}
.ed-dropdowns-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.ed-dropdown-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ed-dropdown-container:not(:last-child) {
  margin-right: 5px; /* Espacio reducido entre los cuadros */
}

@media (max-width: 900px) {
  .ed-dropdowns-container {
    flex-direction: column;
  }
  
  .ed-dropdown-container:not(:last-child) {
    margin-right: 0;
    margin-bottom: 10px; /* Espacio entre los cuadros en modo de una columna */
  }
}

.ed-body-text-style-3{
font-family: NovecentoDemibold;
font-size: 23pt;
color: #168EA8;
font-weight: normal;
font-style: normal;
text-decoration: none;
line-height: 45pt;
}

.ed-body-style-3 {
    background-color: rgba(255, 255, 255, 0.7);
    opacity: 0.9;
    width: 100%;
    max-width: 1000px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    max-height: 200px;
  }
   .ed-dropdown-button-aux {
    background-color: #2aa9a3;
    opacity:0.9;
    width: 100%;
    max-width: 1000px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 0px;
   
      
  }
   .ed-dropdown-button {
    background-color: #2aa9a3;
    opacity:0.9;
    width: 100%;
    max-width: 1000px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 20px;
   
      
  }
  @media (max-width: 900px) {
        
    .ed-dropdown-button {
      height: 100%;
      max-height: 110px;
    }
  
  }
  

.ed-text-and-circulo {
    display: flex; 
    align-items: center;
     max-width: 800px; /* Ajusta el ancho máximo del elemento */
  max-height: 250px; /* Ajusta la altura máxima del elemento */
}

@media (max-width: 768px) {
  .ed-text-and-circulo {
    flex-direction: column;
     max-width: 100%; /* Ajusta el ancho máximo del elemento */
  max-height: 100%;
    
  }
}

.card {
        max-width: 830px;
        height: 98%;
        display: inline-block;
        margin-top: 3%;
        margin: 5px 5px 5px 5px;
        background-color: rgba(255, 255, 255, 0);
        margin-left:8%;
    }
    
@media (max-width: 768px) {
  .card {
    margin-left:auto;
  }
}
.ed-container {
  display: flex;
  flex-direction: column;
  margin: 0px 80px 0px 80px;
  padding-bottom: 20px;
}

.ed-item {
  width: 100%;
  max-width: 850px;
}

.ed-left {
  align-self: flex-start;
}

.ed-right {
  align-self: flex-end;
}

@media (max-width: 600px) {
  .ed-container {
    flex-direction: column;
    margin: 5px 5px 0px 5px;
  }

  .ed-left, .ed-right {
    align-self: auto;
  }
}

.ed-topography-dropdown-content{
    font-size: 26px;
    color: #3C810D;

}

@media (max-width: 600px) {
  .ed-topography-dropdown-content {
      padding-left: 15px;
      padding-right: 15px;
      font-size: 26px;
      color: #3C810D;
  }

}

.ed-button-topography {
  position: relative;
  text-align:center;
  margin-left:10px;
  margin-right:10px;
  margin-top:10px;
}


/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 16-may-2020, 20:45:04
    Author     : Leo
*/

.center-image {
  position: relative;
}

.MuiAvatar-img {
    
    object-fit: cover !important;
}
.leaflet-container {
  height: 420px;
  width: 100%;
}
/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 18-may-2020, 22:37:52
    Author     : Leo
*/

.cs-left {
  margin-left: 12.4%;
  margin-right: 12.4%;
  position: static;
  position: initial;
  float:none;
}
.cs-right {
    margin-left: 12.4%;
    margin-right: 12.4%;
    position: static;
    position: initial;
    float:none;
}
.imgSocobioma{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto; 
    width: 100%;
    max-width: 842px;
    height: auto;
}

.slider-container {
}

.my_fullscreen_div {
    width: 100%;
    height: 100%;
}

.texto-izquierda {
  margin-bottom: 20px;
  text-align: center;
}

.contenedor {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color:#6ececd;
}

.circulo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: rgb(50, 161, 153);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  margin: 50px;
}

.circulo:hover {
  transform: scale(1.1);
}

.circulo img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 55%;
}

@media (max-width: 768px) {
  .circulo {
    width: 100px;
    height: 100px;
  }
}
/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 3 jun. 2023, 21:37:45
    Author     : Leo
*/
.image-links-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Permite que las imágenes se envuelvan en filas adicionales cuando no haya suficiente espacio horizontal */
  background-color: #b4b4b4;
  
  
}



.image-links-container img {
  width: 100%; /* Ajusta el tamaño de las imágenes según tus necesidades */
  height: 100%;
  max-width: 10%;
  max-height:10%;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  margin: 50px; /* Espacio vertical entre las imágenes */
  margin-right:7%;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

.image-links-container img {
  display: none; /* Oculta todas las imágenes por defecto */
}


.image-links-container img.active {
  display: block; /* Muestra solo la imagen activa */
}

.image-links-container img:hover {
  transform: scale(1.1);
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
}

@media (max-width: 768px) {
  .image-links-container img {
    width: 100%; /* Ajusta el tamaño de las imágenes para pantallas más pequeñas */
    height: 100%;
    max-width: 25%;
  max-height:25%;
  }
}

.scaling-animation {
  -webkit-animation: scaleAnimation 0.5s ease-in-out infinite alternate;
          animation: scaleAnimation 0.5s ease-in-out infinite alternate;
}

@-webkit-keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%)
  }
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%)
  }
}

.scaling-animation:hover {
  -webkit-animation: none;
          animation: none;
  transform: scale(1.1);
}

.pa-img-urusal {
  max-height:4% !important; 
  max-width:4% !important;
}
@media (max-width: 760px) {
  .pa-img-urusal {
   max-width:12% !important;
         
  }
}

.pa-img-fallabrino {
  max-height:6% !important; 
  max-width:6% !important;
}
@media (max-width: 760px) {
  .pa-img-fallabrino {
   max-width:12% !important;
         
  }
}


.screen-patrocina-container {
  position: relative;
}
.leaflet-container {
  height: 420px;
  width: 100%;
}
.responsiveLogo{
    width: 80%;
    height: 80%;
}

.successCard{
    text-align: center
}
