/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 16-may-2020, 20:45:04
    Author     : Leo
*/

.center-image {
  position: relative;
}

.MuiAvatar-img {
    
    object-fit: cover !important;
}