/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 3 jun. 2023, 21:37:45
    Author     : Leo
*/
.image-links-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Permite que las imágenes se envuelvan en filas adicionales cuando no haya suficiente espacio horizontal */
  background-color: #b4b4b4;
  
  
}



.image-links-container img {
  width: 100%; /* Ajusta el tamaño de las imágenes según tus necesidades */
  height: 100%;
  max-width: 10%;
  max-height:10%;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  margin: 50px; /* Espacio vertical entre las imágenes */
  margin-right:7%;
  filter: grayscale(100%);
}

.image-links-container img {
  display: none; /* Oculta todas las imágenes por defecto */
}


.image-links-container img.active {
  display: block; /* Muestra solo la imagen activa */
}

.image-links-container img:hover {
  transform: scale(1.1);
  filter: grayscale(0%);
}

@media (max-width: 768px) {
  .image-links-container img {
    width: 100%; /* Ajusta el tamaño de las imágenes para pantallas más pequeñas */
    height: 100%;
    max-width: 25%;
  max-height:25%;
  }
}

.scaling-animation {
  animation: scaleAnimation 0.5s ease-in-out infinite alternate;
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
    filter: grayscale(0%)
  }
}

.scaling-animation:hover {
  animation: none;
  transform: scale(1.1);
}

.pa-img-urusal {
  max-height:4% !important; 
  max-width:4% !important;
}
@media (max-width: 760px) {
  .pa-img-urusal {
   max-width:12% !important;
         
  }
}

.pa-img-fallabrino {
  max-height:6% !important; 
  max-width:6% !important;
}
@media (max-width: 760px) {
  .pa-img-fallabrino {
   max-width:12% !important;
         
  }
}


.screen-patrocina-container {
  position: relative;
}