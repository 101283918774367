/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 10-may-2020, 19:27:17
    Author     : Leo
*/
.left {
  margin: 8.1%;
}
.right {
  margin: 8.1%;
}

.MuiAvatar-img{
    object-fit: contain !important;
}

.dropdown-container {
  display: inline-table;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}


.ns-dropdown-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2aa9a3;
  max-width: 1000px;
  opacity:0.9;
   width: 100%;
   height:50px;
   justify-content: center;
     border-radius: 5px;
         margin: 0 auto;
         margin-top:30px;
}
@media (max-width: 450px) {
  .ns-dropdown-button {
    display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2aa9a3;
  max-width: 1000px;
  opacity:0.9;
   width: 100%;
   height:85px;
   justify-content: center;
border-radius: 5px;
    margin: 0 auto;
    margin-top:5px;
         
  }
  

}
   




.circle-container {
  width: 230px; /* Ajusta el tamaño del cuadrado según tus necesidades */
  height: 210px;
  /*border: 1px solid #000;*/ /*PARA PONER BORDE*/
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.7);
}

.circle {
     
 width: 155px;
  height: 135px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;

  overflow: hidden;
}

.circle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dropdown-content {
  width: 230px;
  background-color: #fff;
  padding: 10px;
  text-align: center;
  margin-bottom: 8px;
}
.dropdowns-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.dropdown-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropdown-container:not(:last-child) {
  margin-right: 5px; /* Espacio reducido entre los cuadros */
}

@media (max-width: 900px) {
  .dropdowns-container {
    flex-direction: column;
  }
  
  .dropdown-container:not(:last-child) {
    margin-right: 0;
    margin-bottom: 10px; /* Espacio entre los cuadros en modo de una columna */
  }
}

.body-text-style-3{
font-family: NovecentoDemibold;
font-size: 23pt;
color: #168EA8;
font-weight: normal;
font-style: normal;
text-decoration: none;
line-height: 45pt;
}

.body-style-3 {
    background-color: rgba(255, 255, 255, 0.7);
    opacity: 0.9;
    width: 100%;
    max-width: 1000px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    max-height: 200px;
  }
  
   .dropdown-button {
    background-color: #2aa9a3;
    opacity:0.9;
    width: 100%;
    max-width: 1000px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 20px;
   
      
  }
  @media (max-width: 900px) {
        
    .dropdown-button {
      height: 100%;
      max-height: 110px;
    }
  
  }