/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 10-may-2020, 19:27:17
    Author     : Leo
*/

.left {
  margin: 8.1%;
}
.right {
  margin: 8.1%;
}

.MuiAvatar-img{
    object-fit: contain !important;
}

.nt-dropdown-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;  
}

.nt-dropdown-container:not(:last-child) {
  margin-right: 5px; /* Espacio reducido entre los cuadros */
}

@media (max-width: 900px) {
  .nt-dropdowns-container {
    flex-direction: column;
  }
  
  .nt-dropdown-container:not(:last-child) {
    margin-right: 0;
    margin-bottom: 10px; /* Espacio entre los cuadros en modo de una columna */
  }
}

.nt-dropdown-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.nt-circle-container {
  width: 260px; /* Ajusta el tamaño del cuadrado según tus necesidades */
  height: 260px;
  /*border: 1px solid #000;*/ /*PARA PONER BORDE*/
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.9);

}

.nt-circle {
    
  width: 185px;
  height: 185px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  margin-bottom:20px;
  overflow: hidden;
}

.nt-circle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.nt-dropdown-content {
    border-radius: 5px;
  width: 260px;
  background-color: #fff;
  padding: 10px;
  text-align: center;
  margin-bottom: 8px;
}
.nt-dropdowns-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}




