.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

}
.card {
  width: 100%;
  max-width: 750px;
  background-color: rgba(255, 255, 255, 0.95)
}