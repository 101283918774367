.spinner {
    width: 100%;
    height: 400px;
  }
  
  .spinner > div {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  