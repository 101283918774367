/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 18-may-2020, 22:37:52
    Author     : Leo
*/

.cs-left {
  margin-left: 12.4%;
  margin-right: 12.4%;
  position: initial;
  float:none;
}
.cs-right {
    margin-left: 12.4%;
    margin-right: 12.4%;
    position: initial;
    float:none;
}