.texto-izquierda {
  margin-bottom: 20px;
  text-align: center;
}

.contenedor {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color:#6ececd;
}

.circulo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: rgb(50, 161, 153);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  margin: 50px;
}

.circulo:hover {
  transform: scale(1.1);
}

.circulo img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 55%;
}

@media (max-width: 768px) {
  .circulo {
    width: 100px;
    height: 100px;
  }
}