.slide{
    background-color: #b4b4b4 !important; /**poner color del slider del carrusel rgb(21, 93, 108)*/
}

.carousel-container .carousel .slide {
  opacity: 0.6;
  transition: opacity 0.8s ease-in-out;
}
.carousel .control-dots {
    z-index:999;
    margin: 10px -15px !important;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
}
.carousel-container .carousel .slide.selected {
  opacity: 1;
}
.carousel-container {
  position: relative;
  overflow: hidden; /* Asegura que el contenido del carrusel no se salga del contenedor */
}

.carousel-container::after {
    content:'';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px; /* Ajusta la altura del sombreado según tus necesidades */
  background: linear-gradient(to top, rgba(0, 0, 0, 0.6), transparent);
}

.rounded-image {
  border-radius: 5px; /* Establece un radio de 10px para lograr esquinas redondeadas */
}